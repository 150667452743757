import React from "react";

import { Layout } from "../components/Layout";
import { Page } from "../components/Page";
import { Title } from "../components/Title";
import { Row, Column } from "../style";

export default function TeamPage() {
  return (
    <Layout>
      <Page
        pageTitle="Our Team"
        pageDescription="Takiwā's culture and team"
        backgroundUrl={
          "https://storage.googleapis.com/takiwa-gatsby-landing-images/hero_bg_team.jpg"
        }
        backgroundPosition="150% 150%"
      >
        <Title>Our Team</Title>
        <Row>
          <Column span="3">
            <img
              src="https://storage.googleapis.com/takiwa-gatsby-landing-images/mike_taitoko.jpg"
              alt="Mike Taitoko - CEO"
            />
          </Column>
          <Column span="8">
            <h3>Mike Taitoko - CEO</h3>
            <p>
              Mike is of Maniapoto descent is a leading specialist in the field
              of Māori and indigenous economic development. He previously
              founded Tuia Consulting, a leading Māori economic development
              advisory firm in New Zealand and the Pacific. During that time
              Mike built an economic benchmarking tool which led to the creation
              of an online analytical processing platform. The idea was to use
              data to drive better visibility across the policy space and
              improve policy outcomes in areas that need it most. And it worked!
              So Mike formed Takiwā to build innovative tools and technologies
              that drive transformational change in areas that most impact Māori
              and indigenous people globally - improving outcomes in health,
              education, culture and identity, social and environmental
              wellbeing and economic development.
            </p>
            <p>
              Contact Mike:{" "}
              <a href="mailto:mike.taitoko@takiwa.co">mike.taitoko@takiwa.co</a>
            </p>
          </Column>
        </Row>
        <Row>
          <Column span="3">
            <img
              src="https://storage.googleapis.com/takiwa-gatsby-landing-images/michael_gershkovich.jpg"
              alt="Michael Gershkovich - CTO"
            />
          </Column>
          <Column span="8">
            <h3>Michael Gershkovich - CTO</h3>
            <p>
              Michael is responsible for leading the Takiwā’s technical vision
              and development, including managing platform development,
              partnerships, information systems and technology teams. Michael
              plays a key role in delivering innovative software solutions
              across the company. Michael delivers incredible value to the
              business with his practical application of great technologies, and
              leads a highly talented and creative team of developers.
            </p>
            <p>
              Contact Michael:{" "}
              <a href="mailto:michael.gershkovich@takiwa.co">
                michael.gershkovich@takiwa.co
              </a>
            </p>
          </Column>
        </Row>
      </Page>
    </Layout>
  );
}
